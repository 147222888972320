import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Internationalization`}</h1>
    <ul>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` `}<a parentName="li" {...{
          "href": "/Participation-Interface",
          "title": "Participation Interface"
        }}>{`Participation Interface`}</a>{` has been translated into several languages by the community`}
        <ul parentName="li">
          <li parentName="ul">{`You can see these translations in the codebase here: `}<a parentName="li" {...{
              "href": "https://github.com/compdemocracy/polisClientParticipation/tree/master/js/strings"
            }}>{`https://github.com/compdemocracy/polisClientParticipation/tree/master/js/strings`}</a></li>
          <li parentName="ul">{`These translations are automatically loaded based on the browser language of the `}<a parentName="li" {...{
              "href": "/participant",
              "title": "participant"
            }}>{`participant`}</a>{`, ie., if their browser language preference is set to French, text will load in French.`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      